import React from 'react'
import Logo from '../../images/logo.png'
import LogoText from '../../images/logo-text-only-blue.png'

const About = (props) => {
    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }
    return(
        <div className={`wpo-about-area section-padding ${props.abClass}`}>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-12 col-md-12 colsm-12">
                        <div className="wpo-about-text">
                            <div className="wpo-about-title">
                                <span className="mb-4">Yritys</span><br />
                                <img src={Logo} alt="Entisöintipläkkärit Oy - Logo" style={{ maxHeight: "60px", marginLeft: "-3px" }} /> 
                                <img alt="Entisöintipläkkärit Oy" src={LogoText} style={{ maxHeight: "60px", marginLeft: "-10px" }} />
                            </div>
                            <br />
                            <h5>Olemme erikoistuneet entisöintiin ja työkohteemme ovat usein myös arvokiinteistöt. Valmistamme ikkunavesipeltejä sinkitystä taipural/pät ponnistetusta teräsohutlevystä. Myös kuparista ja alumiinistä.</h5>
                            <p>Vuodesta 2008 pääkaupunkiseudulla toimiva peltisepänliike. Meiltä erikoispeltisepäntyöt, konesaumapeltikatot, profiloidut peltikatot, myös metallikaiteet ja metalliaidat kuumasinkittynä tai ruostumattomasta teräksestä.
                                Entisöintipläkkärit Oy on keskittynyt pääsääntöisesti erikoismittaisten julkisivupeltien ja sadevesijärjestelmien tuottamiseen. Erilaiset ikkuna- ja kerrosvesipellit, myös pyöreät ja kaarevat. Pyöreät ja kantikkaat syöksyputket, suppilot, mutkasarjat ja syöksyjen kannakkeet. Myös ulosheittäjät lankoitettuna tai tuubiputket/iskunkestävät syöksyjen alaosat. <br />

                                Alipainetuulettimet ja tarvittaessaan myös kattokartiot. Tuotteet toimitettuna ja tarvittaessaan myös mitoitus ja malliasennus.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About;