import React from 'react';

import 'photoswipe/dist/photoswipe.css'

import { Gallery as PsGallery, Item } from 'react-photoswipe-gallery'

export default function Gallery(props) {
    return (
        <PsGallery>
            {props.images.map((image) => {
                return (
                    <div
                        key={image.id}
                        className={`col-${props.col} col-md-${props.col_md} col-lg-${props.col_lg} p-2`}
                        style={{
                            display: 'block',
                        }}
                    >
                        <Item 
                            original={image.url}
                            thumbnail={image.thumbnail ? image.thumbnail : image.url}
                            width={image.width}
                            height={image.height}
                        >
                            {({ ref, open }) => (
                                <img
                                    alt={`Peltisepänliike Entisöintipläkkärit Oy - ${image.title} - Helsinki, Vantaa, Espoo`}
                                    ref={ref}
                                    onClick={open} src={`${image.url}`}
                                    style={{
                                        cursor: "pointer",
                                        objectFit: 'cover',
                                    }}
                                />
                            )}
                        </Item>
                    </div>
                )
            })}
        </PsGallery>
    );
}