import React, { Fragment } from 'react';
import PageTitle from '../../components/pagetitle'
import Shop from '../../components/Shop'
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
import Logo from '../../images/logo.svg'
import Navbar2 from '../../components/Navbar2';
import { SuperSEO } from "react-super-seo";

import pageBanner from '../../images/banner-products.jpg';
import ShopIntro from '../../components/Shop/intro';

const ShopPage = () => {
    return (
        <Fragment>
            <SuperSEO
                title="Tuotteet | Peltisepänliike Entisöintipläkkärit Oy"
                description=""
                lang="fi"
            />

            <Navbar2 Logo={Logo} hclass={'wpo-header-style-2'} />
            <PageTitle pageTitle={'Tuotteet'} pagesub={'Tuotteet'} banner={pageBanner} />
            <ShopIntro />
            <Shop />
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};
export default ShopPage;

