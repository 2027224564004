import React from 'react';
import { GiFlamingSheet, GiMetalPlate } from 'react-icons/gi';
import { BsSnow2, BsBuilding } from 'react-icons/bs';
import { MdOutlineRoofing } from 'react-icons/md';

import simg from '../images/service-single/1.jpg'
import simgGrid from '../images/service-single/service-metal.jpg'
import simg2 from '../images/service-single/2.jpg'
import simg2Grid from '../images/service-single/service-roof.jpg'
import simg3 from '../images/service-single/3.jpg'
import simg3Grid from '../images/service-single/service-facade.jpg'
import simg4 from '../images/service-single/4.jpg'
import simg4Grid from '../images/service-single/service-snow.jpg'

import sSingleimg1 from '../images/service-single/s1.jpg'
import sSingleimg2 from '../images/service-single/s2.jpg'


import ins1 from '../images/instragram/7.jpg'
import ins2 from '../images/instragram/8.jpg'
import ins3 from '../images/instragram/9.jpg'
import ins4 from '../images/instragram/10.jpg'


const Services = [
    {
        id: 'metallityöt',
        Id: 'metallityöt',
        entityId: 1,
        title: 'Metallityöt',
        link: '/palvelut/metallityöt',
        sImg: simg,
        sImgGrid: simgGrid,
        sTitle: 'Metallityöt',
        description: 'Metallitöiden toimialaan kuuluvat myös erilaiset rakennusalan teräs ja RST- rakenteet.',
        des2: 'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
        des3: 'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        icon: <GiMetalPlate size="3em" color="#0D0845" />,
        imageFolder: "images/service-images/metal",
        imageCount: 0,
        ssImg1: sSingleimg1,
        ssImg2: sSingleimg2,
        sinst: ins1,
        url: '/palvelut/metallityöt'
    },
    {
        id: 'kattopeltityöt',
        Id: 'kattopeltityöt',
        entityId: 2,
        title: 'Kattotyöt',
        link: '/palvelut/kattopeltityöt',
        sImg: simg2,
        sImgGrid: simg2Grid,
        sTitle: 'Kattopeltityöt',
        description: 'Aito, perinteinen konesaumattu peltikatto on näyttävä ja erittäin pitkäikäinen kateratkaisu.',
        des2: 'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
        des3: 'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        icon: <MdOutlineRoofing size="3em" color="#0D0845" />,
        imageFolder: "images/service-images/roof",
        imageCount: 25,
        ssImg1: sSingleimg1,
        ssImg2: sSingleimg2,
        sinst: ins2,
        url: '/palvelut/kattopeltityöt'
    },
    {
        id: 'julkisivupeltityöt',
        Id: 'julkisivupeltityöt',
        entityId: 3,
        title: 'Julkisivu peltityöt',
        link: '/palvelut/julkisivupeltityöt',
        sImg: simg3,
        sImgGrid: simg3Grid,
        sTitle: 'Julkisivu peltityöt',
        description: 'Teemme myös seinien ulkovuorauksia ja julkisivujen pellitystöitä, listoituksia ja esimerkiksi ikkunakoteloita.',
        des2: 'Laadukkailla materiaaleilla voidaan kattaa laajojakin pintoja nopeasti ja kustannustehokkaasti .',
        des3: 'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        icon: <BsBuilding size="3em" color="#0D0845" />,
        imageFolder: "images/service-images/facade",
        imageCount: 0,
        ssImg1: sSingleimg1,
        ssImg2: sSingleimg2,
        sinst: ins3,
        url: '/palvelut/julkisivupeltityöt'
    },
    {
        id: 'lumenpudotus',
        Id: 'lumenpudotus',
        entityId: 4,
        title: 'Lumenpudotus',
        link: '/palvelut/lumenpudotus',
        sImg: simg4,
        sImgGrid: simg4Grid,
        sTitle: 'Lumenpudotus',
        description: 'Vaativat lumityöt ja jään poisto, Myös kerrostalojen katoilta kattoa vaurioittamatta.',
        des2: 'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
        des3: 'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        icon: <BsSnow2 size="3em" color="#0D0845" />,
        imageFolder: "images/service-images/snow",
        imageCount: 0,
        ssImg1: sSingleimg1,
        ssImg2: sSingleimg2,
        sinst: ins4,
        url: '/palvelut/lumenpudotus'
    }
]
export default Services;