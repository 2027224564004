import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import Home from '../Home'
import AboutPage from '../AboutPage'
import ServicePage from '../ServicePage';
import ServicePageS2 from '../ServicePageS2';
import ServiceSinglePage from '../ServiceSinglePage';
import ProjectPage from '../ProjectPage';
import ProjectPageS2 from '../ProjectPageS2';
import ProjectSinglePage from '../ProjectSinglePage';
import TeamSinglePage from '../TeamSinglePage';
import BlogPage from '../BlogPage'
import BlogPageLeft from '../BlogPageLeft'
import BlogPageFullwidth from '../BlogPageFullwidth'
import BlogDetails from '../BlogDetails'
import BlogDetailsFull from '../BlogDetailsFull'
import BlogDetailsLeftSiide from '../BlogDetailsLeftSiide'
import PricingPage from '../PricingPage'
import TestimonialPage from '../TestimonialPage'
import ContactPage from '../ContactPage'
import ShopPage from '../ShopPage'
import ShopSinglePage from '../ShopSinglePage'
import CartPage from '../CartPage'
import CheckoutPage from '../CheckoutPage'
import ErrorPage from '../ErrorPage'
import Privacy from '../Legal/Privacy';
import Cookies from '../Legal/Cookies';


const AllRoute = () => {

  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path='/' component={Home} />
          <Route path='/meistä' component={AboutPage}  />
          <Route path='/yhteystiedot' component={ContactPage} />
          <Route path='/palvelut/:id' component={ServiceSinglePage} />
          <Route path='/palvelut' component={ServicePage} />
          <Route path='/tuotteet/:id' component={ShopSinglePage} />
          <Route path='/tuotteet' component={ShopPage} />
          <Route path='/404' component={ErrorPage} />
          <Route path='/sivut/tietosuoja' component={Privacy} />
          <Route path='/sivut/evästeet' component={Cookies} />
          
          <Route path='/project-single/:id' component={ProjectSinglePage} />
          <Route path='/team-single/:id' component={TeamSinglePage} />
          <Route path='/service-s2' component={ServicePageS2} />
          <Route path='/project' component={ProjectPage} />
          <Route path='/project-s2' component={ProjectPageS2} />
          <Route path='/pricing' component={PricingPage} />
          <Route path='/testimonial' component={TestimonialPage} />
          <Route path='/cart' component={CartPage} />
          <Route path='/checkout' component={CheckoutPage} />
          <Route path='/blog-single/:id' component={BlogDetails} />
          <Route path='/blog-single-left-sidebar/:id' component={BlogDetailsLeftSiide} />
          <Route path='/blog-single-fullwidth/:id' component={BlogDetailsFull} />
          <Route path='/blog' component={BlogPage} />
          <Route path='/blog-left-sidebar' component={BlogPageLeft} />
          <Route path='/blog-fullwidth' component={BlogPageFullwidth} />
        </Switch>
      </Router>

    </div>
  );
}

export default AllRoute;
