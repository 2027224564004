import React, { Component } from 'react'
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from 'react-router-dom';


class ContactForm extends Component {


    state = {
        name: '',
        email: '',
        message: '',
        error: {}
    }

    changeHandler = (e) => {
        const error = this.state.error;
        error[e.target.name] = ''

        this.setState({
            [e.target.name]: e.target.value,
            error
        })
    }

    subimtHandler = (e) => {
        e.preventDefault();

        const {
            name,
            email,
            message, error
        } = this.state;

        if (name === '') {
            error.name = "Ole hyvä ja syötä nimesi";
        }
        if (email === '') {
            error.email = "Ole hyvä ja syötä sähköpostiosoitteesi";
        }
        if (message === '') {
            error.message = "Älä unohda kirjoittaa viestiä :)";
        }


        if (error) {
            this.setState({
                error
            })
        }
        if (error.name === '' && error.email === '' && error.message === '') {
            /* contact here */
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    name: name,
                    email: email,
                    message: message
                })
            };
            fetch('http://api.julkisivuduunarit.fi/form', requestOptions)
                .then(response => response.json())
                .then(data => this.setState({
                    postId: data.id
                }));
            this.setState({
                name: '',
                email: '',
                message: '',
                error: {}
            })
        }
    }

    render() {
        const { name,
            email,
            message,
            error } = this.state;

            const ClickHandler = () => {
                window.scrollTo(10, 0);
            }

        return (
            <form onSubmit={this.subimtHandler} className="form">
                <div className="row justify-content-center">
                    <div className="col-lg-6 col-md-6 col-12">
                        <div className="form-field">
                            <input value={name} onChange={this.changeHandler} type="text" name="name" placeholder="Nimi" />
                            <p>{error.name ? error.name : ''}</p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-12">
                        <div className="form-field">
                            <input onChange={this.changeHandler} value={email} type="email" name="email" placeholder="Sähköposti" />
                            <p>{error.email ? error.email : ''}</p>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="form-field">
                            <textarea onChange={this.changeHandler} value={message} name="message" placeholder="Vapaamuotoinen viesti"></textarea>
                            <p>{error.message ? error.message : ''}</p>
                        </div>
                    </div>
                    <div className="col-lg-12 col-md-5 mb-2">
                        <ReCAPTCHA
                            sitekey="6LeVGHEUAAAAAHx9q_eiyoGg_YnpegAA8tRWVgb8"
                        />
                        <p className="mt-3">
                            Lomakkeen lähettämällä hyväksyt { }
                            <Link onClick={ClickHandler} to="/sivut/tietosuoja">tietosuojaselosteemme</Link>.
                        </p>
                    </div>
                    <div className="col-lg-12">
                        <div className="form-field">
                            <button
                                type="submit"
                                className="theme-btn w-100"
                            >
                                Lähetä viesti
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        )
    }

}
export default ContactForm;