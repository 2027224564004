import React from 'react'
import { Link, useParams } from 'react-router-dom'
import Prtoducts from '../../api/product'
import ShopTabs from './shoptabs'




import './style.css'

const ShopSingle = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 450);
    }

    console.log(props);

    const { id } = useParams()

    return (
        <section className="page-content">
            <div className="container">
                <div className="page-content-row">
                    <div className="shop-page">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="shop-page-details section-padding">
                                    <div className="shop-items">
                                        <div className="shop-item single">
                                            <div className="row align-items-center">
                                                <div className="col-lg-6">
                                                    <div className="shop-img">
                                                        <img src={props.image} alt="" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="shop-info">
                                                        <h3>{props.product.Title}</h3>
                                                        <h5 className="text-info">Tilaustuote</h5>
                                                        <div className="clearfix"></div>
                                                        <div className="des mb-4 mt-3 ps-3">
                                                            <div dangerouslySetInnerHTML={{ __html: props.product?.Description }} />
                                                        </div>
                                                        <Link onClick={ClickHandler} to="/yhteystiedot" >
                                                            <button type="submit" className="theme-btn">Kysy lisää</button>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )

}
export default ShopSingle;