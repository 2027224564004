import React, {Fragment} from 'react';
import PageTitle from '../../components/pagetitle'
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
import Logo from '../../images/logo.svg'
import { SuperSEO } from "react-super-seo";
import PrivacyContent from '../../components/privacy';
import Navbar2 from '../../components/Navbar2';

const Privacy =() => {
    return(
        <Fragment>
            <SuperSEO
                title="Tietosuoja | Peltisepänliike Entisöintipläkkärit Oy"
                description=""
                lang="fi"
            />

            <Navbar2 Logo={Logo} hclass={'wpo-header-style-2'} />
            <PageTitle pageTitle={'Tietosuoja'} pagesub={'Tietosuoja'}/> 
            <PrivacyContent />
            <Footer ftClass={'wpo-site-footer-s2'}/>
            <Scrollbar/>
        </Fragment>
    )
};
export default Privacy;

