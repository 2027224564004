import React, {Fragment} from 'react';
import PageTitle from '../../components/pagetitle'
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
import Logo from '../../images/logo.svg'
import { SuperSEO } from "react-super-seo";
import Navbar2 from '../../components/Navbar2';
import CookiesContent from '../../components/privacy/cookies';

const Cookies =() => {
    return(
        <Fragment>
            <SuperSEO
                title="Evästeet | Peltisepänliike Entisöintipläkkärit Oy"
                description=""
                lang="fi"
            />

            <Navbar2 Logo={Logo} hclass={'wpo-header-style-2'} />
            <PageTitle pageTitle={'Evästeet'} pagesub={'Tietoa evästeistä'}/> 
            <CookiesContent />
            <Footer ftClass={'wpo-site-footer-s2'}/>
            <Scrollbar/>
        </Fragment>
    )
};
export default Cookies;

