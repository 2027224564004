import React, { Fragment } from 'react';
import Navbar2 from '../../components/Navbar2'
import Hero4 from '../../components/hero4';
import About4 from '../../components/about4';
import ProjectSectionS4 from '../../components/ProjectsS4';
import ServiceSection3 from '../../components/Services3';
import Footer from '../../components/footer';
import Scrollbar from '../../components/scrollbar'
import Logo from '../../images/logo.svg'
import { SuperSEO } from "react-super-seo";
import Brands from '../../components/Brands';
import ProductSectionAnimated from '../../components/ProductSectionAnimated';
import { core } from '../../features/api.js';


const Home = () => {
    const { data } = core.useGetServicesQuery({ search: '' });
    
    return (
        <Fragment>
            <SuperSEO
                title="Peltisepänliike Entisöintipläkkärit Oy"
                description=""
                lang="fi"
            />

            <Navbar2 Logo={Logo} hclass={'wpo-header-style-3'} />
            <Hero4 />
            <ServiceSection3 />
            <ProductSectionAnimated />
            <About4 />
            {/* <ProjectSectionS4 /> */}
            <Brands />
            <Footer ftClass={'wpo-site-footer-s2'} />
            <Scrollbar />
        </Fragment>
    )
};
export default Home;