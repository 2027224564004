import React from 'react'
import { Link } from 'react-router-dom'
import Services from '../../api/service'
import SectionTitle from '../SectionTitle'


const ServiceSection = (props) => {
    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }


    return (
        <div className={`wpo-service-area section-padding ${props.sClass}`}>
            <div className="container">
                <SectionTitle subTitle={'MEIDÄN KYKYMME'} MainTitle={'Palvelut 🔨'}/>
                <div className="row align-items-center">
                    {Services.slice(0,8).map((service, sitem) => (
                        <div className="col-lg-3 col-md-6 col-12" key={sitem}>
                            <div className="wpo-service-item">
                                {service.icon}
                                <h2>{service.sTitle}</h2>
                                <p>{service.description}</p>
                                <Link onClick={ClickHandler} to={`${service.url}`}>Lue lisää...</Link>
                            </div>
                        </div>
                    ))}

                </div>
            </div>
        </div>
    )
}

export default ServiceSection;