import React from 'react';
import ContactForm from '../ContactFrom'


const Contactpage = () => {

    return (
        <section className="wpo-contact-pg-section section-padding">
            <div className="container">
                <div className="row">
                    <div className="col col-lg-10 offset-lg-1">
                        <div className="office-info">
                            <div className="row">
                                <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                                    <div className="office-info-item">
                                        <div className="office-info-icon">
                                            <div className="icon">
                                                <i className="fi flaticon-location"></i>
                                            </div>
                                        </div>
                                        <div className="office-info-text">
                                            <h2>Postiosoite</h2>
                                            <p>PL 1168, Helsinki</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                                    <div className="office-info-item">
                                        <div className="office-info-icon">
                                            <div className="icon">
                                                <i className="fi flaticon-email"></i>
                                            </div>
                                        </div>
                                        <div className="office-info-text">
                                            <h2>Sähköpostitse</h2>
                                            <p><a href="mailto:myynti@julkisivuduunarit.fi">myynti@julkisivuduunarit.fi</a></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                                    <div className="office-info-item">
                                        <div className="office-info-icon">
                                            <div className="icon">
                                                <i className="fi flaticon-telephone"></i>
                                            </div>
                                        </div>
                                        <div className="office-info-text">
                                            <h2>Soita</h2>
                                            <p><a href="tel:0505551039">050 555 1039</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
						{/*
                        <div className="wpo-contact-title">
                            <h2>Onko kysyttävää?</h2>
                            <p>Älä epäröi ottaa meihin yhteyttä.</p>
                        </div>
                        <div className="wpo-contact-form-area">
                            
                        </div>
						*/}
                    </div>
                </div>
            </div>
            <section className="wpo-contact-map-section">
                <div className="wpo-contact-map">
                </div>
            </section>
        </section>
    )

}

export default Contactpage;
