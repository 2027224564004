import React from 'react'
import { MdClear } from 'react-icons/md';

const Category = (props) => {
    return (
        <div
            className={`col-12 col-md-3`}
            style={{ cursor: "pointer", borderRadius: 10 }}
            onClick={props.onClick}
        >
            <div className="card mb-2 mb-md-5">
                <div className={`card-body`} style={props.activeCategory == props.id ? { background: `#31aaf1`} : {}}>
                    <h5 className="card-title text-center" style={{ margin: "auto" }}>
                        {props.icon}
                        <span className={`ms-2 ${props.activeCategory == props.id && `text-white`}`} style={{ verticalAlign: "middle" }}>{props.title}</span>
                        {props.activeCategory == props.id ?
                            <MdClear size="25px" color="#fff" className="ms-1" />
                        : null}
                    </h5>
                </div>
            </div>
        </div>
    )
}

export default Category;