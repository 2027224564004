import React, {Fragment} from 'react';
import PageTitle from '../../components/pagetitle'
import Contactpage from '../../components/Contactpage'
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
import Logo from '../../images/logo.svg'
import Navbar2 from '../../components/Navbar2';
import { SuperSEO } from "react-super-seo";
import pageBanner from '../../images/banner-contact.jpg';

const ContactPage =() => {
    return(
        <Fragment>
            <SuperSEO
                title="Yhteystiedot | Peltisepänliike Entisöintipläkkärit Oy"
                description=""
                lang="fi"
            />

            <Navbar2 Logo={Logo} hclass={'wpo-header-style-2'} />
            <PageTitle pageTitle={'Yhteystiedot'} pagesub={'Yhteystiedot'} banner={pageBanner} /> 
            <Contactpage/>
            <Footer ftClass={'wpo-site-footer-s2'}/>
            <Scrollbar/>
        </Fragment>
    )
};
export default ContactPage;

