import React, { useState, useRef } from 'react'
import { core } from '../../features/api.js';
import { Link } from 'react-router-dom'
import { debounce } from 'lodash'
import { GiHeavyRain, GiMetalPlate, GiWindow } from 'react-icons/gi';
import { MdRoofing } from 'react-icons/md';
import Category from './category.js';

const Shop = (props) => {
    const [searchQuery, setSearchQuery] = useState('');
    const [cat, setCat] = useState('');
    const [activeCategory, setActiveCategory] = useState(0);

    const { data: Products, isLoading, isFetching } = core.useGetProductsQuery({ search: searchQuery, category: cat });

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    const debouncedSearch = useRef(
        debounce(async (search) => {
            setSearchQuery(search);
            console.log(search);
        }, 700)
    ).current

    const handleFilter = e => {
        debouncedSearch(e.target.value)
    }

    const selectCategory = (name, id) => {
        if (activeCategory == id) {
            setCat('');
            setActiveCategory('');
        } else {
            setCat(name);
            setActiveCategory(id);
        }
    }


    return (
        <section className="wpo-shop-section section-padding">
            <div className="container">
                <div className="row mb-5">
                    <Category
                        activeCategory={activeCategory}
                        id="1"
                        onClick={() => selectCategory('peltituotteet', 1)}
                        icon={<GiWindow size="2em" color={activeCategory == 1 ? `#fff` : `#0D0845`} />}
                        title="Peltituotteet"
                    />
                    <Category
                        activeCategory={activeCategory}
                        id="2"
                        onClick={() => selectCategory('metallituotteet', 2)}
                        icon={<GiMetalPlate size="2em" color={activeCategory == 2 ? `#fff` : `#0D0845`} />}
                        title="Metallituotteet"
                    />
                    <Category
                        activeCategory={activeCategory}
                        id="3"
                        onClick={() => selectCategory('sadevesijärjestelmät', 3)}
                        icon={<GiHeavyRain size="2em" color={activeCategory == 3 ? `#fff` : `#0D0845`} />}
                        title="Sadevesijärjestelmät"
                    />
                    <Category
                        activeCategory={activeCategory}
                        id="4"
                        onClick={() => selectCategory('kattotuotteet', 4)}
                        icon={<MdRoofing size="2em" color={activeCategory == 4 ? `#fff` : `#0D0845`} />}
                        title="Kattotuotteet"
                    />
                </div>
                <div className="row">
                    <div className="col col-xs-12">
                        <div className="form-field" style={{ marginTop: "-60px" }}>
                            <input type="text" name="query" placeholder="Hae tuotteita..." className="mb-4" onChange={handleFilter} />
                        </div>
                        <div className="shop-grids clearfix">
                            {!isLoading && Array.isArray(Products.data) ? Products.data.map((item, en) => (
                                <div className="grid" key={en}>
                                    <div className="img-holder">
                                        <img src={'https://strapi.julkisivuduunarit.fi' + item?.attributes?.Images?.data[0]?.attributes?.url} alt="" />
                                    </div>
                                    <div className="details">
                                        <h3><Link onClick={ClickHandler} to={`/tuotteet/${item?.attributes?.Url}`}>{item?.attributes?.Title}</Link></h3>
                                        <div className="add-to-cart">
                                            <Link onClick={ClickHandler} to={`/tuotteet/${item?.attributes?.Url}`}>Lue lisää</Link>
                                        </div>
                                    </div>
                                </div>
                            )) : null}
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default Shop;