import React, { Component } from 'react'
import { Collapse, CardBody, Card } from 'reactstrap';
import { Link } from 'react-router-dom'
import './style.css';
import Services from '../../api/service';
import Products from '../../api/product';

const menus = [
    {
        id: 1,
        title: 'Etusivu',
        link: '/',
    },
    {
        id: 3,
        title: 'Palvelut',
        link: '/palvelut',
        submenu: Services.slice(0, 10)
    },
    {
        id: 4,
        title: 'Tuotteet',
        link: '/tuotteet'
    },
    {
        id: 2,
        title: 'Meistä',
        link: '/meistä',
    },
    {
        id: 88,
        title: 'Yhteystiedot',
        link: '/yhteystiedot',
    }
]


export default class MobileMenu extends Component {

    state = {
        isMenuShow: false,
        isOpen: 0,
    }

    menuHandler = () => {
        this.setState({
            isMenuShow: !this.state.isMenuShow
        })
    }

    setIsOpen = id => () => {
        this.setState({
            isOpen: id === this.state.isOpen ? 0 : id
        })
    }

    render() {

        const { isMenuShow, isOpen } = this.state;

        const ClickHandler = () => {
            window.scrollTo(10, 0);
        }

        return (
            <div>
                <div className={`mobileMenu ${isMenuShow ? 'show' : ''}`}>
                    <div className="menu-close" style={{ zIndex: "100" }}>
                        <div className="clox" onClick={this.menuHandler}><i className="ti-close"></i></div>
                    </div>

                    <ul className="responsivemenu">
                        {menus.map(item => {
                            return (
                                <li key={item.id}>
                                    {item.submenu ? <p onClick={this.setIsOpen(item.id)}>
                                        {item.title}
                                        {item.submenu ? <i className={`fa fa-angle-${item.id === isOpen ? 'down' : 'right'}`} aria-hidden="true"></i> : ''}
                                    </p> : <Link onClick={ClickHandler} to={item.link}>{item.title}</Link>}
                                    {item.submenu ?
                                        <Collapse isOpen={item.id === isOpen}>
                                            <Card>
                                                <CardBody>
                                                    <ul>
                                                        {item.submenu.map(submenu => (
                                                            <li key={submenu.id}><Link onClick={ClickHandler} className="active" to={submenu.link}>{submenu.title}</Link></li>
                                                        ))}
                                                    </ul>
                                                </CardBody>
                                            </Card>
                                        </Collapse>
                                        : ''}
                                </li>
                            )
                        })}
                    </ul>

                    <div className="mt-2 p-3">
                        <Link to="/yhteystiedot" className="theme-btn-s3 w-100 text-center">Pyydä tarjousta</Link>
                    </div>
                </div>

                <div className="showmenu pull-right" onClick={this.menuHandler}>
                    <button type="button" className="navbar-toggler open-btn">
                        <span className="icon-bar first-angle"></span>
                        <span className="icon-bar middle-angle"></span>
                        <span className="icon-bar last-angle"></span>
                    </button>
                </div>
            </div>
        )
    }
}
