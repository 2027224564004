import React from 'react'
import lindab from '../../images/brands/lindab.png';
import metehe from '../../images/brands/metehe.png';
import rala from '../../images/brands/rala.png';
import tilaajavastuu from '../../images/brands/tilaajavastuu.png';
import luotettava from '../../images/brands/luotettava-kumppani.png';

const Brands = (props) => {
    return (
        <div className={`wpo-service-area-s3 section-padding d-md-block d-none ${props.sClass}`} style={{ background: "#16161a" }}>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-2 col-md-4 col-12" style={{ opacity: "0.6" }}>
                        <a href="http://www.metehe.fi/?utm_source=www.julkisivuduunarit.fi&utm_medium=partner&utm_campaign=website" target="_blank">
                            <img alt="Metehe" src={metehe} />
                        </a>
                    </div>
                    <div className="col-lg-2 col-md-4 col-12" style={{ opacity: "0.6" }}>
                        <a href="http://www.lindab.fi/?utm_source=www.julkisivuduunarit.fi&utm_medium=partner&utm_campaign=website" target="_blank">
                            <img alt="Lindab" src={lindab} />
                        </a>
                    </div>
                    <div className="col-lg-3 col-md-4 col-12" style={{ opacity: "0.6" }}>
                        <a href="http://www.metehe.fi/?utm_source=www.julkisivuduunarit.fi&utm_medium=partner&utm_campaign=website" target="_blank">
                            <img alt="Rala" src={rala} />
                        </a>
                    </div>
                    <div className="col-lg-3 col-md-4 col-12" style={{ opacity: "0.6" }}>
                        <a href="https://zeckit.com/selvitys/FI/2217626-9?lang=fi&utm_source=www.julkisivuduunarit.fi&utm_medium=partner&utm_campaign=website" target="_blank">
                            <img alt="Tilaajavastuu" src={tilaajavastuu} />
                        </a>
                    </div>
                    <div className="col-lg-2 col-md-4 col-12" style={{ opacity: "0.6" }}>
                        <a href="https://zeckit.com/selvitys/FI/2217626-9?lang=fi&utm_source=www.julkisivuduunarit.fi&utm_medium=partner&utm_campaign=website" target="_blank">
                            <img alt="Luotettava kumppani" src={luotettava} />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Brands;