import React from 'react'
import { Link } from 'react-router-dom'
import { FaHandshake, FaTruck } from 'react-icons/fa';
import { MdDesignServices } from 'react-icons/md';
import { HiSparkles } from 'react-icons/hi';

const ShopIntro = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <section className="wpo-shop-section container mb-5 mb-sm-0 mt-5 mt-sm-5" style={{ marginTop: "35px", marginBottom: "-35px" }}>
            <div className="row">
                <div className="col-xs-12 col-sm-6 col-md-3 feature  feature-1">
                    <div className="feature-icon">
                        <FaHandshake size="3em" color="#0D0845" className="mb-2" />
                        <i className="lnr lnr-phone font-40 color-heading"></i>
                    </div>
                    <h4 className="text-uppercase font-16">KONSULTOINTI</h4>
                    <p>Tarvitsetko apua suunnittelussa tai heräsikö kysymyksiä pellin käytöstä rakentamisessa? Ota yhtyettä!</p>
                </div>
                <div className="col-xs-12 col-sm-6 col-md-3 feature  feature-1">
                    <div className="feature-icon">
                        <MdDesignServices size="3em" color="#0D0845" className="mb-2" />
                    </div>
                    <h4 className="text-uppercase font-16">SUUNNITTELU</h4>
                    <p>Suunnittelemme kanssasi yhteistyössä juuri sinulle sopivat peltituotteet.</p>
                </div>
                <div className="col-xs-12 col-sm-6 col-md-3 feature  feature-1">
                    <div className="feature-icon">
                        <HiSparkles size="3em" color="#0D0845" className="mb-2" />
                    </div>
                    <h4 className="text-uppercase font-16">RÄÄTÄLÖIDYT TUOTTEET</h4>
                    <p>Valmistamme tuotteet tarpeidesi mukaan mittatilaustyönä.</p>
                </div>
                <div className="col-xs-12 col-sm-6 col-md-3 feature  feature-1">
                    <div className="feature-icon">
                        <FaTruck size="3em" color="#0D0845" className="mb-2" />
                    </div>
                    <h4 className="text-uppercase font-16">TOIMITUS</h4>
                    <p>Tarvittaessa toimitamme tilauksenne pääkaupunkiseudulla, kysy lisää <Link to="/yhteystiedot">tästä</Link>
                    </p>
                </div>
            </div>
        </section>

    )
}

export default ShopIntro;