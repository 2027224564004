import React, {Fragment} from 'react';
import Navbar from '../../components/Navbar'
import PageTitle from '../../components/pagetitle'
import Services from '../../components/Services'
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
import Logo from '../../images/logo.svg'
import Navbar2 from '../../components/Navbar2';
import { SuperSEO } from "react-super-seo";

import pageBanner from '../../images/banner-services.jpg';

const ServicePage =() => {
    return(
        <Fragment>
            <SuperSEO
                title="Palvelut | Peltisepänliike Entisöintipläkkärit Oy"
                description=""
                lang="fi"
            />

            <Navbar2 Logo={Logo} hclass={'wpo-header-style-2'} />
            <PageTitle pageTitle={'Palvelut'} pagesub={'Palvelut'} banner={pageBanner} /> 
            <Services/>
            <Footer ftClass={'wpo-site-footer-s2'}/>
            <Scrollbar/>
        </Fragment>
    )
};
export default ServicePage;
