import React, { Component, useState } from 'react'
import { core } from '../../features/api'
import { Link } from 'react-router-dom'
import Products from '../../api/product'
import Projects from '../../api/project'
/* import Services from '../../api/service' */
import MobileMenu from '../../components/MobileMenu'
import Logo from '../../images/logo-text-only.png'
import AAA from '../../images/AAA-logo-2021-FI-transparent.png'


const Header2 = ({ hclass }) => {
    const [isSidebarShow, setIsSidebarShow] = useState(false);

    /* state = {
        isSidebarShow: false,
    }

    sidebarHandler = () => {
        this.setState({
            isSidebarShow: !this.state.isSidebarShow
        })
    } */

    const { data: Services, isLoading } = core.useGetServicesQuery({ search: '' });
    const { data: Products, isLoading: productsLoading } = core.useGetProductsQuery({ search: '' });

    const SubmitHandler = (e) => {
        e.preventDefault()
    }
    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }
    return (
        <header id="header">
            <div className={`wpo-site-header ${hclass}`}>
                <nav className="navigation navbar navbar-expand-lg navbar-light">
                    <div className="container-fluid">
                        <div className="row align-items-center">
                            <div className="col-lg-2 col-md-8 col-8">
                                <div className="navbar-header">
                                    <Link onClick={ClickHandler} className="navbar-brand text-white pull-left lg-pull-none" to="/">
                                        <img src={Logo} alt="" style={{ marginTop: "3px" }} />
                                    </Link>
                                </div>
                            </div>
                            <div className="col-lg-8 col-md-1 col-1">
                                <div id="navbar" className="collapse navbar-collapse navigation-holder">
                                    <button className="menu-close"><i className="ti-close"></i></button>
                                    <ul className="nav navbar-nav mb-2 mb-lg-0">
                                        <li><Link onClick={ClickHandler} to="/">Etusivu</Link></li>
                                        <li className="menu-item-has-children">
                                            <Link to="/palvelut">Palvelut</Link>
                                            <ul className="sub-menu">
                                                <li><Link onClick={ClickHandler} to="/palvelut">Kaikki</Link></li>
                                                {!isLoading && Array.isArray(Services.data) ? Services.data.slice(0, 4).map((service, pot) => (
                                                    <li key={pot}><Link onClick={ClickHandler} to={`/palvelut/${service?.attributes?.Title.toLowerCase()}`}>{service?.attributes?.Title}</Link></li>
                                                )) : null}
                                            </ul>
                                        </li>
                                        <li className="menu-item-has-children">
                                            <Link onClick={ClickHandler} to="/tuotteet">Tuotteet</Link>
                                            <ul className="sub-menu">
                                                <li><Link onClick={ClickHandler} to="/tuotteet">Kaikki</Link></li>
                                                {!productsLoading && Array.isArray(Products.data) ? Products.data.slice(0, 4).map((product, pot) => (
                                                    <li key={pot}>
                                                        <Link onClick={ClickHandler} to={`/tuotteet/${product?.attributes?.Url}`}>
                                                            {product?.attributes?.Title}
                                                        </Link>
                                                    </li>
                                                )) : null}
                                            </ul>
                                        </li>
                                        <li><Link onClick={ClickHandler} to="/meistä">Yritys</Link></li>
                                        <li><Link onClick={ClickHandler} to="/yhteystiedot">Yhteystiedot</Link></li>
                                    </ul>

                                </div>
                            </div>
                            <div className="col-lg-2 col-md-1 col-2 d-md-none d-sm-none d-none d-lg-block">
                                <div className="header-right">
                                    <div className="header-right-menu-wrapper">
                                        <div className="header-right-menu">
                                            {/* <Link className="theme-btn-s3">Pyydä tarjousta</Link> */}
                                            <div className="right-menu-toggle-btn" onClick={() => setIsSidebarShow(true)}>
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                            </div>

                                            <div className={`header-right-menu-wrap ${isSidebarShow ? 'right-menu-active' : ''}`}>
                                                <button onClick={() => setIsSidebarShow(false)} className="right-menu-close"><i className="ti-close"></i></button>
                                                <div className="logo"><img src={Logo} alt="" /></div>
                                                <div className="header-right-sec">
                                                    <div className="widget wpo-contact-widget">
                                                        <div className="widget-title">
                                                            <h3>Ota yhteyttä</h3>
                                                        </div>
                                                        <div className="contact-ft">
                                                            <ul>
                                                                <li><i className="fi flaticon-location"></i>PL 1168, Helsinki (Postiosoite)</li>
                                                                <li><i className="fi flaticon-telephone"></i><a style={{ color: '#b6b6ff' }} href="tel:0505551039">050 555 1039</a></li>
                                                                <li><i className="fi flaticon-email"></i><a style={{ color: '#b6b6ff' }} href="mailto:myynti@julkisivuduunarit.fi">myynti@julkisivuduunarit.fi</a></li>
                                                            </ul>
                                                            <img
                                                                src={AAA}
                                                                style={{ filter: 'brightness(0) invert(1)', maxHeight: '150px' }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-3 col-3 d-lg-none dl-block">
                                <div className="mobail-menu">
                                    <MobileMenu />
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        </header>
    )
}

export default Header2;