import React from 'react'
import { core } from '../../features/api'
import { Link } from 'react-router-dom'
import Logo from '../../images/logo.png'
import LogoText from '../../images/logo-text-only-blue.png'
import AAA from '../../images/AAA-logo-2021-FI-transparent.png'
import Projects from '../../api/project'
import Services from '../../api/service'
import Products from '../../api/product'



const Footer = (props) => {

    const { data: Products, isLoading: productsLoading } = core.useGetProductsQuery({ search: '' });
    const { data: Services, isLoading: servicesLoading } = core.useGetServicesQuery({ search: '' });

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <footer className={`wpo-site-footer ${props.ftClass}`}>
            <div className="wpo-upper-footer">
                <div className="container">
                    <div className="row">
                        <div className="col col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="widget about-widget">
                                <div className="logo widget-title">
                                    <Link onClick={ClickHandler} className="logo" to="/">
                                        <img src={Logo} alt="" style={{ maxHeight: "100px", marginLeft: "-10px" }} />
                                        <img src={LogoText} alt="" style={{ maxHeight: "100px", marginLeft: "-10px" }} />
                                    </Link>
                                </div>
                                <p>Vuodesta 2008 pääkaupunkiseudulla toimiva peltisepänliike. <br />
                                    Meiltä erikoispeltisepäntyöt, konesaumapeltikatot, profiloidut peltikatot, myös metallikaiteet ja metalliaidat kuumasinkittynä tai ruostumattomasta teräksestä.
                                </p>
                                <ul>
                                    <li>
                                        <a href="https://www.facebook.com/peltisepanliike/" target="_">
                                            <i className="ti-facebook"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.instagram.com/peltisepanliike/" target="_">
                                            <i className="ti-instagram"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col col-xl-3  col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="widget link-widget">
                                <div className="widget-title">
                                    <h3>Palvelut 🔨</h3>
                                </div>
                                <ul>
                                    <li><Link onClick={ClickHandler} to="/palvelut">Kaikki</Link></li>
                                    {!servicesLoading && Array.isArray(Services.data) ? Services.data.slice(0, 4).map((service, sitem) => (
                                        <li key={sitem}>
                                            <Link onClick={ClickHandler} to={`/palvelut/${service?.attributes?.Url}`}>
                                                {service?.attributes?.Title}
                                            </Link>
                                        </li>
                                    )) : null}
                                </ul>
                            </div>
                        </div>
                        <div className="col col-xl-3  col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="widget link-widget">
                                <div className="widget-title">
                                    <h3>Tuotteet ✨</h3>
                                </div>
                                <ul>
                                    <li><Link onClick={ClickHandler} to="/palvelut">Kaikki</Link></li>
                                    {!productsLoading && Array.isArray(Products.data) ? Products.data.slice(0, 4).map((product, item) => (
                                        <li key={item}>
                                            <Link onClick={ClickHandler} to={`/tuotteet/${product?.attributes?.Url}`}>
                                                {product?.attributes?.Title}
                                            </Link>
                                        </li>
                                    )) : null}
                                </ul>
                            </div>
                        </div>

                        <div className="col col-xl-3  col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="widget wpo-service-link-widget">
                                <div className="widget-title">
                                    <h3>Yhteystiedot ✉️ </h3>
                                </div>
                                <div className="contact-ft">
                                    <ul>
                                        <li><i className="fi flaticon-location"></i>PL 1168, Helsinki (Postiosoite)</li>
                                        <li>
                                            <i className="fi flaticon-telephone"></i>
                                            <a href="tel:0505551039">050 555 1039</a>
                                        </li>
                                        <li>
                                            <i className="fi flaticon-email"></i>
                                            <a href="mailto:myynti@julkisivuduunarit.fi">myynti@julkisivuduunarit.fi</a>
                                        </li>
                                        <li style={{ paddingLeft: 0, marginLeft: '-20px' }}>
                                            <img 
                                                src={AAA}  
                                                style={{ filter: 'brightness(0) invert(1)' }}
                                            />
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="wpo-lower-footer">
                <div className="container">
                    <div className="row">
                        <div className="col col-xs-12">
                            <ul>
                                <li>&copy; 2022 Entisöintipläkkärit Oy.</li>
                                <li>
                                    <Link onClick={ClickHandler} to="/sivut/tietosuoja">Tietosuoja | </Link>
                                    <Link onClick={ClickHandler} to="/sivut/evästeet">Tietoa Evästeistä</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;