import React from 'react'
import { Link } from 'react-router-dom'


const PrivacyContent = (props) => {
    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <section className="section-padding">
            <div className="container">
                <div className="row">
                    <div className="col col-xs-12">
                        <div className="content clearfix">
                            <div className="error-message text-left">
                                <h4>1. Rekisterinpitäjä</h4>
                                <p>
                                    Entisöintipläkkärit Oy <br />
                                    PL 1168, Helsinki <br />
                                    Puh: 050 555 1039 <br />
                                    Sähköposti: myynti ( ät ) julkisivuduunarit.fi
                                </p>
                                <h4 className="mt-4">2. Yhteyshenkilö rekisteriä koskevissa asioissa</h4>
                                <p>
                                    Marcos Raudkett <br />
                                    Sähköposti: info ( ät ) marcosraudkett.com <br />
                                    Nettisivu: <a target="_blank" href="https://marcosraudkett.com">https://marcosraudkett.com</a>
                                </p>
                                <h4 className="mt-4">3. Rekisterin nimi</h4>
                                <p>
                                    Entisöintipläkkärit Oy:n asiakas ja markkinointirekisteri
                                </p>
                                <h4 className="mt-4">4. Henkilötietojen käsittelyn tarkoitus</h4>
                                <p>
                                    Henkilötietoja käytetään asiakassuhteiden hoitamiseen, yhteydenottojen hoitamiseksi, markkinointitarkoituksiin sekä muihin verkkopalveluihin liittyviin tarkoituksiin.
                                </p>
                                <h4 className="mt-4">5. Rekisterin tietosisältö</h4>
                                <p>
                                    Säilytämme rekisterissä myös tiedot antamastasi palautteesta, viestien vaihdoista ja muista yhteydenotoista asiakaspalvelumme  kanssa sekä tiedot markkinointisuostumuksista ja -kielloista.
                                </p>
                                <h4 className="mt-4">6. Säännönmukaiset tietolähteet</h4>
                                <p>
                                    Henkilötietoja kerätään verkosivuilta yhteydenottolomakkeiden täytön yhteydessä. Lisäksi henkilötietoja voidaan kerätä yrityksen muista käytössä olevista tietojärjestelmistä sekä rekisterinpitäjän toimintoihin liittyvistä sosiaalisista medioista, mikäli henkilö on antanut erikseen kirjallisesti suostumuksen tietojen käytölle Entisöintipläkkärit Oy:n asiakas- ja markkinointirekisterissä tai hyödyntämisestä markkinointitarkoituksiin. Henkilötietoja voidaan täydentää myös muista yrityksen hallinnoimista, keräämistä tai ostamista henkilötietoja käsittelevistä palveluista. <br /><br />

                                    Keräämme käyttäjistä verkkosivujen sisällön käytön perusteella tietoja evästeiden ja vastaavien tekniikoiden avulla. Näitä tietoja ei yhdistetä Entisöintipläkkärit Oy:n asiakas- ja markkinointirekisterin kanssa. (ks. kohta Tietoa evästeistä verkkosivuilla).
                                </p>
                                <h4 className="mt-4">7. Tietojen säännönmukaiset luovutukset</h4>
                                <p>
                                    Rekisterinpitäjä voi luovuttaa tietoja voimassaolevan lainsäädännön sallimissa ja velvoittamissa rajoissa muun muassa rekisterinpitäjän valikoimille yhteistyökumppaneille markkinointitarkoituksiin, ellei rekisteröity ole kieltänyt tietojensa tällaista luovuttamista. <br />

                                    Henkilötietoja voidaan lisäksi luovuttaa voimassaolevan lainsäädännön sallimissa ja velvoittavissa rajoissa tuomioistuimelle, poliisille tai ulosottoviranomaiselle.
                                </p>
                                <h4 className="mt-4">8. Tietojen siirto EU:n tai ETA:n ulkopuolelle</h4>
                                <p>
                                    Tietoja voidaan siirtää EU:n tai Euroopan talousalueen ulkopuolelle henkilötietolain sallimalla tavalla.
                                </p>
                                <h4 className="mt-4">9. Rekisterin suojauksen perusteet</h4>
                                <p>
                                    Rekisterissä olevat henkilötiedot säilytetään luottamuksellisina. Tietoverkko ja laitteisto, jolla rekisteri sijaitsee on suojattu palomuurilla ja muilla tarvittavilla teknisillä toimenpiteillä. Tietojen siirto lomakkeiden avulla on sähköisesti suojattu. Tietokannat ja niiden varmuuskopiot sijaitsevat lukituissa tiloissa ja niiden käyttöön ovat oikeutettuja vain ne työntekijät ja yhteistyökumppanit, joilla on työnsä puolesta oikeus käsitellä tietoja.
                                </p>
                                <h4 className="mt-4">10. Tarkastusoikeus</h4>
                                <p>
                                    Rekisteröidyllä on oikeus tarkastaa häntä koskevat asiakas- ja markkinointirekisterissä olevat tiedot. Kirjallinen tarkastuspyyntö tulee lähettää allekirjoitettuna rekisteriasioista vastaavalle henkilölle. Tarkastuspyyntö voidaan myös esittää henkilökohtaisesti rekisterinpitäjän toimipaikassa yllä mainitussa osoitteessa. Tarkastuspyyntöihin vastataan mahdollisimman nopeasti.
                                </p>
                                <h4 className="mt-4">11. Tiedon korjaaminen</h4>
                                <p>
                                    Rekisteröidyillä on oikeus kieltää tietojensa käyttäminen suoramainontaan, etämyyntiin tai muuhun suoramarkkinointiin sekä mielipide- ja markkinatutkimukseen ja oikeus vaatia virheellisen tiedon korjaamista ottamalla yhteyttä rekisteriasioista vastaavaan henkilöön tai ilmoittamalla siitä rekisterinpitäjän asiakaspalveluun. Muutokset rekisteriin pyritään suorittamaan viimeistään kuukauden kuluessa ilmoituksesta.
                                </p>
                                <br />
                                <p>
                                    Henkilötietolaki (523/1999) 10§ <br />
                                    Laatimispäivä: 11.01.2019
                                </p>
                                <div className="text-center mt-5">
                                    <Link onClick={ClickHandler} to="/" className="theme-btn"><i className="fa fa-angle-double-left"></i> Takaisin etusivulle</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default PrivacyContent;