import React from 'react'
import { Link } from 'react-router-dom'

import pageTitle from '../../images/page-title.jpg';

const PageTitle = (props) => {

    const backgroundImage = () => {
        return props.banner ?
            `url("${props.banner}") no-repeat center top/cover` :
            `url("${pageTitle}") no-repeat center top/cover`;
    };

    const navigate = () => {
        if (Array.isArray(props.pagesub)) {
            return (
                props.pagesub.map((item, i) => (
                    <li key={i}>
                        {!item.isActive ?
                            <Link to={item.link}>{item.title}</Link>
                            : <span>{item.title}</span>}
                    </li>
                ))
            )
        } else {
            return <li><span>{props.pagesub}</span></li>
        }
    }

    return (
        <section className="wpo-page-title" style={{
            background: backgroundImage(),
            minHeight: '250px'
        }}>
            <div className="container">
                <div className="row">
                    <div className="col col-xs-12">
                        <div className="wpo-breadcumb-wrap">
                            <h2>{props.pageTitle}</h2>
                            <ol className="wpo-breadcumb-wrap">
                                <li><Link to="/">Etusivu</Link></li>
                                {navigate()}
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default PageTitle;