import React from 'react';
import AllRoute from '../router'
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../sass/style.scss';
import { core } from '../../features/api';


const App = () => { 
  const { data: services, isLoading: servicesLoading } = core.useGetServicesQuery({ search: '' });
  const { data: products, isLoading: productsLoading } = core.useGetProductsQuery({ search: '' });
  return (
    <div className="App" id='scrool'>
          <AllRoute/>
          <ToastContainer/>
    </div>
  );
}

export default App;
