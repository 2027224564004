import React, { useRef } from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from 'react-router-dom';

import { useInView } from "framer-motion";

function AnimatedServiceItem(props) {
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true });

    return (
        <div className="row d-flex justify-content-center section-padding" style={{ paddingTop: "5px" }} ref={ref}>
            {props.reversed ? <div className="col-12 col-md-6">
                <img alt="Peltisepänliike Entisöintipläkkärit Oy" className="w-100" src={props.image} style={{
                    borderRadius: "5px",
                    transform: isInView ? "none" : "translateY(75px)",
                    opacity: isInView ? 1 : 0,
                    transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.8s"
                }} />
            </div> : null}
            <div className="col-12 col-md-6">
                {props.icon}
                <h2 className="text-white mb-3" style={{
                    transform: isInView ? "none" : "translateY(75px)",
                    opacity: isInView ? 1 : 0,
                    transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.3s"
                }}>{props.title}</h2>
                <p style={{
                    color: "#CBCBCB",
                    transform: isInView ? "none" : "translateY(75px)",
                    opacity: isInView ? 1 : 0,
                    transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s"
                }}>
                    {props.text}
                </p>
                <Link to={props.link} className="theme-btn-s3 mb-4 mb-md-0" style={{
                    transform: isInView ? "none" : "translateY(75px)",
                    opacity: isInView ? 1 : 0,
                    transition: "all 1s cubic-bezier(0.17, 0.55, 0.55, 1) 1s"
                }}>Lue lisää</Link>
            </div>
            {!props.reversed ? <div className="col-12 col-md-6">
                <img alt="Peltisepänliike Entisöintipläkkärit Oy" className="w-100" src={props.image} style={{
                    borderRadius: "5px",
                    transform: isInView ? "none" : "translateY(75px)",
                    opacity: isInView ? 1 : 0,
                    transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.8s"
                }} /></div> : null}
        </div>
    );
}

export default AnimatedServiceItem;