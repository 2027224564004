import sp1 from '../images/shop/1.jpg'
import sp2 from '../images/shop/2.jpg'
import sp3 from '../images/shop/3.jpg'
import sp4 from '../images/shop/4.jpg'
import sp5 from '../images/shop/5.jpg'
import sp6 from '../images/shop/6.jpg'
import sp7 from '../images/shop/7.jpg'
import sp8 from '../images/shop/8.jpg'
import sp9 from '../images/shop/9.jpg'
import sp10 from '../images/shop/10.jpg'


/* const IkkunapeltiDesc = () => {
    return (
      <div>
        Vesipeltien kiinnitystapoja on monia erilaisia. Takareuna kiinnittyy ikkunakarmin uraan lukkiutuvasti tai ruuvikiinnityksellä sinkityillä tai värillisillä ruuveilla, usein myös rst- tai messinki ruuveilla. Etureunan kiinnitykseen käytetään joskus liimamassaa, mutta pääsääntöisesti lukko-/monitoimipeltiä ja julkisivurappauksen yhteydessä usein myös lankalammerin kiinnitystä. Lankalammerikiinnitystä käytetään ylimaalattavalla pellityksellä. Lammeri kiinnitetään esireunasta noin 60-70mm ja päistä noin 100-105mm max 700mm välein, etenemä vaihtelee tapauskohtaisesti. Lankalammerin kiinnitykseen käytetään kuparilevyniittiä tai harvemmin alumiini painekestäviä popniittejä.
        Ikkunapeltejä/vesipeltejä mitottaessa huomioi seuraavat:

        1. Etureunan ylitys normaalisti 30-35mm valmiista julkisivupinnasta
        2. Takareunan kiinnitystapa
        3. Vesipellin tippanokan korkeus/koko. Yleensä 20,25 tai 30mm
        4. Päädyn ylösnostonkorkeus. Meiltä saa ikkunapellit valmiiksi ylösnostetuilla päädyillä Suora ylösnosto on yleensä 30mm, rappauskantin nosto 25mm ja kantti normaalisti 10mm, harvemmin 15-20mm
        5. Ikkunapenkin kaato. Suositeltava moin 1:5.
        Mitat voi toimittaa kuvatiedostona, tai skannattuna sähköpostiimme lähetettynä.
      </div>
    )
} */

const Products = [
  {
    id: 'ikkunapellit',
    link: '/tuotteet/ikkunapellit',
    shopImg: sp1,
    price: "25.00",
    off: "$20.00",
    title: "Ikkunapellit",
    desc: 'Valmistamme ikkunavesipeltejä sinkitystä tai tehdasmaalatusta teräsohutlevystä. Myös kuparista ja alumiinista (0,5mm, 0,6mm, 0,7mm). Vesipeltien kiinnitystapoja on monia erilaisia. Takareuna kiinnittyy ikkunakarmin uraan lukkiutuvasti tai ruuvikiinnityksellä sinkityillä tai värillisillä ruuveilla, usein myös rst- tai messinki ruuveilla. Etureunan kiinnitykseen käytetään joskus liimamassaa, mutta pääsääntöisesti lukko-/monitoimipeltiä ja julkisivurappauksen yhteydessä usein myös lankalammerin kiinnitystä. Lankalammerikiinnitystä käytetään ylimaalattavalla pellityksellä. Lammeri kiinnitetään esireunasta noin 60-70mm ja päistä noin 100-105mm max 700mm välein, etenemä vaihtelee tapauskohtaisesti. Lankalammerin kiinnitykseen käytetään kuparilevyniittiä tai harvemmin alumiini painekestäviä popniittejä.',
    btn: "Add to cart",
    brand: "Humanscale",
    url: '/tuotteet/ikkunapellit'
  },
  {
    id: 'syöksyputket',
    link: '/tuotteet/syöksyputket',
    shopImg: sp2,
    price: "25.00",
    off: "$20.00",
    title: "Syöksyputket",
    desc: 'Pyöreät ja kantikkaat syöksyputket, suppilot, mutkasarjat ja teräksiset syöksyjen kannakkeet.',
    path: "syöksyputket",
    btn: "Add to cart",
    brand: "Amia",
    url: '/tuotteet/syöksyputket'
  },
  {
    id: 'alipainetuulettimet',
    link: '/tuotteet/alipainetuulettimet',
    shopImg: sp3,
    price: "25.00",
    off: "$20.00",
    title: "Alipainetuulettimet",
    desc: 'Alipainetuulettimet katon tuuletukseen ja tuulihatut.',
    btn: "Add to cart",
    brand: "Liberty",
    url: '/tuotteet/alipainetuulettimet'
  },
  {
    id: 'kinnikkeet-pyöreät',
    link: '/tuotteet/kinnikkeet-pyöreät',
    shopImg: sp6,
    price: "25.00",
    off: "$20.00",
    title: "Kinnikkeet (Pyöreät)",
    desc: '-',
    btn: "Add to cart",
    brand: "Humanscale",
    url: '/tuotteet/kinnikkeet-pyöreät'
  },
  {
    id: 'kinnikkeet-kantikkaat',
    link: '/tuotteet/kinnikkeet-kantikkaat',
    shopImg: sp7,
    price: "25.00",
    off: "$20.00",
    title: "Kinnikkeet (Kantikkaat)",
    desc: '-',
    btn: "Add to cart",
    brand: "Humanscale",
    url: '/tuotteet/kinnikkeet-kantikkaat'
  },
  {
    id: 'keräilykartiot',
    link: '/tuotteet/keräilykartiot',
    shopImg: sp9,
    price: "25.00",
    off: "$20.00",
    title: "Keräilykartiot",
    desc: '-',
    btn: "Add to cart",
    brand: "Humanscale",
    url: '/tuotteet/keräilykartiot'
  },
  {
    id: 'tuubiputket',
    link: '/tuotteet/tuubiputket',
    shopImg: sp4,
    price: "25.00",
    off: "$20.00",
    title: "Tuubiputket",
    desc: 'Tuubiputket sinkityt ja ruostumattomasta teräksestä, Kantikkaat, pyöreät ja erikoisvalmisteiset.',
    btn: "Add to cart",
    brand: "Liberty",
    url: '/tuotteet/tuubiputket'
  },
  {
    id: 'kattoluukut',
    link: '/tuotteet/kattoluukut',
    shopImg: sp5,
    price: "25.00",
    off: "$20.00",
    title: "Kattoluukut",
    desc: 'Tuotteelle ei ole lisätty kuvausta.',
    btn: "Add to cart",
    brand: "Akhtar",
    url: '/tuotteet/kattoluukut'
  },
  {
    id: 'aidat-ja-kaiteet',
    link: '/tuotteet/aidat-ja-kaiteet',
    shopImg: sp8,
    price: "25.00",
    off: "$20.00",
    title: "Aidat ja kaiteet",
    desc: 'Kaiteet portaisiin ja parvekkeisiin, Portit ja aidat sinkittynä, maalattuna tai ruostumattomasta teräksestä.',
    btn: "Add to cart",
    brand: "Humanscale",
    url: '/tuotteet/aidat-ja-kaiteet'
  },
  {
    id: 'peltilistat',
    link: '/tuotteet/peltilistat',
    shopImg: sp10,
    price: "25.00",
    off: "$20.00",
    title: "Peltilistat",
    desc: '-',
    btn: "Add to cart",
    brand: "Humanscale",
    url: '/tuotteet/peltilistat'
  },
]

export default Products;