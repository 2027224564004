// ** Redux Imports
import { configureStore } from "@reduxjs/toolkit";
import { coreSlice } from '../features/coreSlice/coreSlice';

const store = configureStore({
    reducer: {
        [coreSlice.reducerPath]: coreSlice.reducer
    },
    middleware: getDefaultMiddleware => getDefaultMiddleware().concat(coreSlice.middleware)
});

export { store };